import { inject, Injectable } from '@angular/core'
import { from, Observable, switchMap } from 'rxjs'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { environment } from 'src/environments/environment'
import { LogService } from './log.service'
import { MockService } from './mock.service'
import { of } from 'rxjs'
import { KeysService } from './keys.service'

declare global {
  interface Window { grecaptcha: any; }
}

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private logger = inject(LogService)
  private mockService = inject(MockService)
  private fetchService = inject(FetchService)
  private apiRequestService = inject(ApiRequestService)
  private keysService = inject(KeysService)

  init() {
    this.logger.debug('FormService.init()')
    this.loadReCaptcha()
  }

  isRecaptchaLoaded(): boolean {
    return !!this.keysService.keys().recaptcha_site_key && this.keysService.keys().recaptcha_site_key !== ''
  }

  private loadReCaptcha() {
    this.logger.debug('FormService.loadReCaptcha()')
    const keys = this.keysService.keys()

    if (!keys.recaptcha_site_key) {
      this.logger.debug('FormService.loadReCaptcha() - No key found')
      return
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${keys.recaptcha_site_key}`
    script.async = true
    document.body.appendChild(script)
    // this adds an object "grecaptcha" to the global window object
  }

  private execute(action: string): Promise<string> {
    if (!window.grecaptcha) {
      return new Promise((success) => success(''))
    }
    return window.grecaptcha.execute(this.keysService.keys().recaptcha_site_key, { action })
  }

  submitContactForm(formData: any): Observable<any> {
    this.logger.debug(`FormService.submitContactForm() formData: `, formData)
    if (environment.useMock) {
      return of(this.mockService.submitContactForm())
    }

    return from(this.execute('contactForm'))
      .pipe(switchMap(token => {
        this.logger.debug('FormService.submitContactForm() recaptcha token: ', token)
        return this.fetchService.postRequest(this.apiRequestService.buildEndpoint('form/submit/webshop'), { ...formData, token })
      }))
  }
}
