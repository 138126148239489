<div class="range-container">
  <div class="input-boxes">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@rangeFilter_MinLabel">Min</mat-label>
      @if (this.isCurrencyField()) {
        <span class="currency" matPrefix>{{globalDataService.selectedCurrency().symbol}}</span>
      }
      <input
        [attr.data-qa-id]="'filterRangeMin' + filterId()"
        matInput
        type="number"
        [(ngModel)]="selectedRange[0]"
        (change)="handleChange()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@rangeFilter_MaxLabel">Max</mat-label>
      @if (this.isCurrencyField()) {
        <span class="currency" matPrefix>{{globalDataService.selectedCurrency().symbol}}</span>
      }
      <input
        [attr.data-qa-id]="'filterRangeMax' + filterId()"
        matInput
        type="number"
        [(ngModel)]="selectedRange[1]"
        (change)="handleChange()">
    </mat-form-field>
  </div>
  <div class="slider">
    <mat-slider [min]="minMaxValues[0]" [max]="minMaxValues[1]" [step]="1">
      <input
        matSliderStartThumb
        [(ngModel)]="selectedRange[0]"
        (dragEnd)="handleSliderDragEnd($event, 0)">
      <input
        matSliderEndThumb
        [(ngModel)]="selectedRange[1]"
        (dragEnd)="handleSliderDragEnd($event, 1)">
    </mat-slider>
  </div>
  @if (this.isCurrencyField()) {
    <div class="minmax">
      <span class="min">{{ minMaxValues[0] | currency : globalDataService.selectedCurrency().code : 'symbol-narrow' : '1.0-0' }}</span>
      <span class="max">{{ minMaxValues[1] | currency : globalDataService.selectedCurrency().code : 'symbol-narrow' : '1.0-0' }}</span>
    </div>
  }
  @if (!this.isCurrencyField()) {
    <div class="minmax">
      <span class="min">{{ minMaxValues[0] }}</span>
      <span class="max">{{ minMaxValues[1] }}</span>
    </div>
  }
</div>
