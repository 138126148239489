import { Component, Inject, OnInit } from '@angular/core'
import { ConfigService } from './core/services/config.service'
import { DevService } from './core/services/dev.service'
import { AnalyticsService } from './core/services/analytics.service'
import { FormService } from './core/services/form.service'
import { HeapService } from './core/services/heap.service'
import { AppInitService, AppInitStatus } from './core/services/app-init.service'
import { DOCUMENT } from '@angular/common'
import { ThemeService } from './core/services/theme.service'
import { EventService } from './core/services/event.service'
import { toObservable } from '@angular/core/rxjs-interop'
import { TagManagerService } from './core/services/tag-manager.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sales-webshop'
  adminMode: boolean = false

  theme: any
  loadedOverrides: any

  status: AppInitStatus = AppInitStatus.Loading
  AppInitStatus = AppInitStatus

  constructor(
    private _configService: ConfigService,
    private _devService: DevService,
    private _analyticsService: AnalyticsService,
    private _tagManagerService: TagManagerService,
    private _formService: FormService,
    private _heapService: HeapService,
    private _appInitService: AppInitService,
    private _themeService: ThemeService,
    private _eventService: EventService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    toObservable(this._appInitService.status).subscribe((status: AppInitStatus) => {
      switch (status) {
        case AppInitStatus.Success:
          this.theme = this._configService.config().theme
          this._tagManagerService.init()
          this._devService.init()
          this._analyticsService.init()
          this._formService.init()
          this._heapService.init()
          this.addThemeToBody()
          break
        case AppInitStatus.SuccessAdmin:
          this._devService.init()
          break
      }
      this.status = status
    })
  }

  ngOnInit() {
    this._eventService.configUpdated$.subscribe(() => {
      if (this.loadedOverrides !== this._configService.config().theme.overrides) {
        this._themeService.clearStylesOnElement(this._document.body)
        this._themeService.setStylesOnElement(this._configService.config().theme.overrides, this._document.body)
        this.loadedOverrides = this._configService.config().theme.overrides
      }
    })
  }

  addThemeToBody() {
    this._document.body.classList.add(this._configService.config().theme.id)
    this._themeService.setStylesOnElement(this._configService.config().theme.overrides, this._document.body)
    this.loadedOverrides = this._configService.config().theme.overrides
  }
}
