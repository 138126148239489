<div class="ContactForm">
  @if (submitStatus !== 'success') {
    <form  #form="ngForm" [formGroup]="contactForm" class="ContactForm__form" (ngSubmit)="submit()">
      <div class="grid-container">
        <div class="grid-x grid-margin-x">
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_FirstNameInputLabel">First name</mat-label>
              <input data-qa-id="contactFirstName" data-testid="contactFirstName" matInput placeholder="First name" i18n-placeholder="@@contactForm_FirstNameInputPlaceholder" required formControlName="first_name">
              @if (firstName?.errors?.['required']) {
                <mat-error i18n="@@contactForm_FirstNameInputError">First name is required</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_LastNameInputLabel">Last name</mat-label>
              <input data-qa-id="contactLastName" data-testid="contactLastName" matInput placeholder="Last name" i18n-placeholder="@@contactForm_LastNameInputPlaceholder" required formControlName="last_name">
              @if (lastName?.errors?.['required']) {
                <mat-error i18n="@@contactForm_LastNameInputError">Last name is required</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_EmailInputLabel">E-mail</mat-label>
              <input data-qa-id="contactEmail" data-testid="contactEmail" matInput placeholder="E-mail" i18n-placeholder="@@contactForm_EmailInputPlaceholder" required formControlName="email">
              @if (email?.errors?.['required']) {
                <mat-error i18n="@@contactForm_EmailInputErrorRequired">Email is required</mat-error>
              }
              @if (email?.errors?.['email']) {
                <mat-error i18n="@@contactForm_EmailInputErrorInvalid">Email is invalid</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_PhoneInputLabel">Phone</mat-label>
              <input data-qa-id="contactPhone" data-testid="contactPhone" matInput placeholder="Phone" i18n-placeholder="@@contactForm_PhoneInputPlaceholder" required formControlName="phone" mask="(AAA) AAA-AAAA||+AAAAAAAAAAAAAAA" [validation]="false">
              @if (phone?.errors?.['required']) {
                <mat-error i18n="@@contactForm_PhoneInputErrorRequired">Phone is required</mat-error>
              }
              @if (phone?.errors?.['pattern']) {
                <mat-error i18n="@@contactForm_PhoneInputErrorInvalid">Phone is invalid</mat-error>
              }
              @if (phone?.errors?.['minlength']) {
                <mat-error i18n="@@contactForm_PhoneInputErrorInvalid">Phone is invalid</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_CompanyInputLabel">Company</mat-label>
              <input data-qa-id="contactCompany" data-testid="contactCompany" matInput placeholder="Company name" i18n-placeholder="@@contactForm_CompanyInputPlaceholder" required formControlName="company_name">
              @if (company?.errors?.['required']) {
                <mat-error i18n="@@contactForm_CompanyInputError">Company name is required</mat-error>
              }
            </mat-form-field>
          </div>
          @if (type() === 'asset') {
            <div class="cell medium-12 offer">
              <mat-form-field appearance="outline">
                <mat-label i18n="@@contactForm_MyOfferInputLabel">My Offer</mat-label>
                <input data-qa-id="offer" data-testid="offer" matInput placeholder="My Offer" i18n-placeholder="@@contactForm_MyOfferInputPlaceholder" formControlName="offer_price_native" [currencyMask]="{
                  align: 'left',
                  allowNegative: false,
                  precision: 0,
                  prefix: globalData.selectedCurrency().symbol + ' ',
                  nullable: true,
                }">
                <mat-hint><span i18n="@@contactForm_MyOfferInputHint">Offer in </span>{{globalData.selectedCurrency().code}}</mat-hint>
              </mat-form-field>
            </div>
          }
          <div class="cell medium-12">
            <mat-form-field appearance="outline">
              <mat-label i18n="@@contactForm_CommentInputLabel">Comment (optional)</mat-label>
              <textarea data-qa-id="contactMessage" data-testid="contactMessage" matInput placeholder="Message" i18n-placeholder="@@contactForm_CommentInputPlaceholder" formControlName="message" rows="3"></textarea>
            </mat-form-field>
          </div>
        </div>
        @if (!hideSubmitButton()) {
          <app-button
            btn-large
            btn-contained
            [disabled]="disabled">
            <span
              data-testid="contactSubmit"
              i18n="@@contactForm_SendMessageButton">
              Send message
            </span>
          </app-button>
        }
        </div>
     </form>
  } @else {
    <div class="ContactForm__success">
      <div class="icon-bg"><mat-icon class="material-icons-outlined icon-large">mark_email_read</mat-icon></div>
      <h3 data-testid="successTitle" class="ContactForm__header">{{i18n.get(successTitle())}}</h3>
      <p data-testid="successBody" class="ContactForm__copy">{{i18n.get(successBody())}}</p>
      @if (withCloseButton()) {
        <app-button
          btn-large
          btn-contained
          (click)="onClose.emit()">
          <span
            i18n="@@contactForm_CloseButton"
            data-testid="closeButton">
            Close
          </span>
        </app-button>
      }
  </div>
  }
</div>