import { Injectable } from '@angular/core'
import { ConfigService } from './config.service'
import { ClientService } from './client.service'
import { LogService } from './log.service'
import { FilterService } from './filter.service'
import { CurrencyService } from './currency.service'
import { GlobalDataService } from './global-data.service'

@Injectable()
export class DevService {
  constructor(
    private logger: LogService,
    private configService: ConfigService,
    private clientService: ClientService,
    private filterService: FilterService,
    private currencyService: CurrencyService,
    private globalData: GlobalDataService,
  ) {}

  init(): void {
    (window as any).signals = () => this.signals()
  }

  signals(): void {
    this.logger.debug('Client: ', this.clientService.client())
    this.logger.debug('Config: ', this.configService.config())
    this.logger.debug('Config Metadata ', this.configService.configMetadata ? this.configService.configMetadata() : null)
    this.logger.debug('Filters ', this.filterService.filters())
    this.logger.debug('All Currencies ', this.currencyService.allCurrencies())
    this.logger.debug('Loaded Currencies ', this.currencyService.loadedCurrencies())
    this.logger.debug('Default Currency ', this.globalData.defaultCurrency())
    this.logger.debug('Selected Currency ', this.currencyService.selectedCurrency())
  }
}
