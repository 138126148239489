import { Pipe, PipeTransform, inject } from '@angular/core'
import { IAsset } from '../interfaces/asset.interface'
import { GlobalDataService } from '../services/global-data.service'


@Pipe({
  name: 'assetMeter',
  standalone: true,
})
export class AssetMeterPipe implements PipeTransform {

  private _globalData = inject(GlobalDataService)

  transform(asset: IAsset, output: 'full' | 'short' | 'amount' = 'full'): any {
    const { meter_hours, meter_miles_precise, display_meter_uom } = asset
    const distanceUnit = this._globalData.unitOfDistance()

    if (['hours', 'H'].includes(display_meter_uom) && meter_hours && meter_hours > 0) {
      if (output === 'amount') {
        return meter_hours?.toLocaleString('en-US')
      }
      return `${meter_hours.toLocaleString('en-US')} ${output === 'short' ? 'hrs' : 'hours'}`
    }

    if (['miles', 'M', 'kilometers', 'K'].includes(display_meter_uom) && meter_miles_precise) {
      const distance = Math.round(meter_miles_precise)
      const label = {
        full: 'miles',
        short: 'mi',
      }
      if (distanceUnit === 'kilometers' || ['kilometers', 'K'].includes(display_meter_uom)) {
        label.full = 'kilometers'
        label.short = 'km'
      }
      if (output === 'amount') {
        return distance?.toLocaleString('en-US')
      }
      return `${distance.toLocaleString('en-US')} ${output === 'short' ? label.short : label.full}`
    }

    return 'N/A'
  }
}
