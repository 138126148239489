import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core'
import { ImageService } from 'src/app/core/services/image.service'
import { defaultLegacyImageProcessorOptions, ILegacyImageProcessorOptions } from '../interfaces/image.interface'
import { ImageSizes } from '../interfaces/media-library.interface'
import { GlobalDataService } from '../services/global-data.service'

@Directive({
  standalone: true,
  selector: '[legacyImageProcessor]',
})
export class LegacyImageProcessorDirective implements OnInit {
  @Input() legacyImageProcessor: ILegacyImageProcessorOptions = defaultLegacyImageProcessorOptions

  @HostBinding('src') src!: string

  constructor(
    public eleRef: ElementRef,
    private imageService: ImageService,
    private globalDataService: GlobalDataService
  ) {}

  ngOnInit(): void {
    this.setSrc()
  }

  setSrc(): void {
    this.src = this.imageService.buildLegacyProcessorSrc(this.legacyImageProcessor)
    const logo = this.globalDataService.config().header?.data?.logo?.src_data?.urls?.[ImageSizes.Thumbnail]

    if (logo && this.globalDataService.config().general?.data?.watermark_logo) {
      this.imageService.applyWatermark(this.src, logo).then(dataUrl => {
        this.src = dataUrl
      })
    }
  }

}
