import { Injectable, WritableSignal, signal } from '@angular/core'
import { LogService } from './log.service'
import { MockService } from './mock.service'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { EventService } from './event.service'
import { FetchService } from './fetch.service'
import { take } from 'rxjs'
import { ApiRequestService } from './api-request.service'
import { EnvironmentService } from './environment.service'
import { HttpErrorResponse } from '@angular/common/http'
import { CurrencyCode, ICurrency, ICurrencyRateData, supportedCurrencies, usdCurrency } from '../interfaces/currency.interface'
import { StorageService } from './storage.service'
import { GlobalDataService } from './global-data.service'

declare global {
  interface Window { currencyService: CurrencyService; }
}

@Injectable()
export class CurrencyService {

  private _localSelectedCurrencyKey: string = 'selected-currency'

  allCurrencies: WritableSignal<ICurrency[]> = signal(supportedCurrencies)
  loadedCurrencies: WritableSignal<ICurrency[]> = signal([])
  selectedCurrency: WritableSignal<ICurrency> = signal(usdCurrency)

  constructor(
    private logger: LogService,
    private mockService: MockService,
    private eventService: EventService,
    private fetchService: FetchService,
    private apiRequestService: ApiRequestService,
    private envService: EnvironmentService,
    private storageService: StorageService,
    private globalData: GlobalDataService,
  ) {
    window.currencyService = this
    this.eventService.currenciesChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.loadCurrencies()
    })
    this.eventService.currencySelected$.pipe(
      takeUntilDestroyed(),
    ).subscribe((code: CurrencyCode) => {
      this.setSelectedCurrency(code)
    })
    toObservable(this.loadedCurrencies).pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.globalData.loadedCurrencies.set(this.loadedCurrencies())
    })
    toObservable(this.selectedCurrency).pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.globalData.selectedCurrency.set(this.selectedCurrency())
    })
  }

  initSelectedCurrency(): void {
    const localSelectedCurrency = this.storageService.getItem(this._localSelectedCurrencyKey)
    this.setSelectedCurrency(localSelectedCurrency as CurrencyCode ?? this.globalData.config().currency.default)
  }

  async loadRates(): Promise<void> {
    this.logger.debug('CurrencyService.loadRates()')
    if (this.envService.get().useMock) {
      const rates = this.mockService.getCurrencyRates()
      this.allCurrencies.set(
        this.allCurrencies().map(currency => ({
          ...currency,
          rate: rates.find(r => r.currency === currency.code)?.rate,
        }))
      )
      this.logger.debug('Mock rates loaded: ', this.allCurrencies())
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      this.fetchService.postRequest<ICurrencyRateData[]>(`${this.apiRequestService.buildEndpoint('currency')}`, {
        currency: Object.values(CurrencyCode),
      }).pipe(
        take(1),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to load currency rates.  ', response)
          return reject('Unable to load currency rates.')
        }
        this.allCurrencies.set(
          this.allCurrencies().map(currency => ({
            ...currency,
            rate: response.find(r => r.currency === currency.code)?.rate,
          }))
        )
        this.logger.debug('Rates loaded: ', this.allCurrencies())
        return resolve()
      })
    })
  }

  loadCurrencies(): Promise<void> {
    this.loadedCurrencies.set([])
    this.globalData.config().currency.available.forEach(code => {
      const currency = this.allCurrencies().find(c => c.code === code)
      if (!currency) {
        this.logger.warn('Tried to load unavailable currency: ', code)
        return
      }
      this.loadedCurrencies().push(currency)
    })
    this.verifyData()
    this.logger.debug('Currencies loaded: ', this.loadedCurrencies())
    return Promise.resolve()
  }

  initCurrenciesForEmbedded(): void {
    this.loadedCurrencies.set(window.parent.currencyService.loadedCurrencies())
    this.allCurrencies.set(window.parent.currencyService.allCurrencies())
    this.logger.debug('Currencies loaded for embedded: ', this.loadedCurrencies())
  }

  private verifyData(): void {
    // default must be a valid loaded currency
    if (!this.loadedCurrencies().find(c => c.code === this.globalData.config().currency.default)) {
      this.globalData.config().currency.default = this.loadedCurrencies()?.[0]?.code ?? CurrencyCode.USD
    }
    this.globalData.defaultCurrency.set(this.globalData.config().currency.default)
  }

  setSelectedCurrency(code: string) {
    const setCurrency = this.loadedCurrencies().find(c => c.code === code) ?? usdCurrency
    this.selectedCurrency.set(setCurrency)
    this.storageService.setItem(this._localSelectedCurrencyKey, setCurrency.code)
    this.logger.debug('Setting selected currency to code: [', code, '] resulted in => ', this.selectedCurrency())
  }

  getSelectedCurrency(): ICurrency {
    return this.selectedCurrency()
  }
}
