import { Injectable, inject } from '@angular/core'
import { I18nText } from '../interfaces/i18n.service'
import { LanguageCode } from '../interfaces/language.interface'
import { GlobalDataService } from './global-data.service'

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private _globalDataService = inject(GlobalDataService)

  get(text: Partial<I18nText> | string | undefined, langCode?: LanguageCode): string {
    if (typeof text === 'string' || !text) {
      return text ?? ''
    }
    if (langCode) {
      return text[langCode] ?? ''
    }
    let str = text[this._globalDataService.selectedLanguage().code]
    if (!str) {
      str = text[this._globalDataService.defaultLanguage()]
    }
    if (!str) {
      str = ''
    }
    return str
  }
}
