import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Component, OnInit, inject, input, model } from '@angular/core'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatSliderChange, MatSliderDragEvent, MatSliderModule } from '@angular/material/slider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { filter } from 'rxjs'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { CurrencyCode, ICurrency } from 'src/app/core/interfaces/currency.interface'
import { PriceService } from 'src/app/core/services/price.service'
import { IJsonLogic } from 'src/app/core/interfaces/json-logic.interface'
import { FilterId } from 'src/app/core/interfaces/filter.interface'
import { GlobalDataService } from 'src/app/core/services/global-data.service'


export interface IRangeFilterState {
  range: number[]
  includeLow: boolean
}

export function getEmptyRangeFilterState(): IRangeFilterState {
  return {
    range: [],
    includeLow: false,
  }
}

export const checkRangeFilterEmpty = (state: IRangeFilterState) => state.range.length === 0

@Component({
  selector: 'app-range-filter',
  standalone: true,
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
  imports: [
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class RangeFilterComponent implements OnInit {
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  private _priceService = inject(PriceService)
  protected globalDataService = inject(GlobalDataService)
  protected filterService = inject(FilterService)

  filterId = input.required<FilterId>()
  state = model.required<IRangeFilterState>()
  filterTitle = input<string>('')
  currency = input<ICurrency>()
  minValue = input<number>(0)
  maxValue = input<number>(1000000)
  includeLow = input<boolean>(true) // use gte vs gt in json logic for low range

  selectedRange: [number, number] = [0,0]
  minMaxValues!: [number, number]

  constructor() {
    toObservable(this.currency).pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.init()
      this.loadFilterValues()
    })

    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(),
      filter(chip => chip.filterId === this.filterId()),
    ).subscribe(() => this.clearAll())

    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => this.clearAll())
  }

  ngOnInit(): void {
    this.init()
    this.loadFilterValues()
  }

  clearAll() {
    this.selectedRange = [...this.minMaxValues]
    this.state.set(getEmptyRangeFilterState())
    this._chipsService.removeChip(this.filterId())
  }

  init() {
    this.minMaxValues = [this.minValue(), Math.ceil(this.maxValue())]
    this.selectedRange = [...this.minMaxValues]
    if (this.isCurrencyField()) {
      this.minMaxValues = this.minMaxValues.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
      this.selectedRange = this.selectedRange.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
    }
  }

  loadFilterValues() {
    this.state().includeLow = this.includeLow()
    if (!checkRangeFilterEmpty(this.state())) {
      const range = this.state().range
      if (this.isCurrencyField()) {
        this.selectedRange = range.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
      } else {
        this.selectedRange = range.map((value: number) => value) as [number, number]
      }
      this.processStateChange()
    }
  }

  adjustCurrencyValue(value: number) {
    return Math.round(this._priceService.calculatePriceForSelectedCurrency(
      value,
      value,
      CurrencyCode.USD,
      this.globalDataService.selectedCurrency().code
    ).price)
  }

  handleChange() {
    if (this.selectedRange[0] === null) {
      this.selectedRange[0] = this.minMaxValues[0]
    }
    if (this.selectedRange[1] === null) {
      this.selectedRange[1] = this.minMaxValues[1]
    }
    this.processStateChange()
  }

  handleSliderDragEnd(event: MatSliderDragEvent, rangeIndex: number) {
    this.selectedRange[rangeIndex] = event.value
    this.processStateChange()
  }

  processStateChange(): void {
    const range = (this.selectedRange[0] !== this.minMaxValues[0] || this.selectedRange[1] !== this.minMaxValues[1])
      ? this.selectedRange.map((value) => Math.round(value / this.getRangeDivisor()))
      : null
    this.state.update(state => {
      if (range) {
        state.range = range
      } else {
        state.range = []
      }
      return { ...state }
    })

    this._chipsService.removeChip(this.filterId())
    if (!checkRangeFilterEmpty(this.state())) {
      const labelText = (this.selectedRange[0] === this.selectedRange[1]
        ? `${this.getChipPrefix()}${this.selectedRange[0]}`
        : `${this.getChipPrefix()}${this.selectedRange[0]} - ${this.getChipPrefix()}${this.selectedRange[1]}`)
      this._chipsService.addChip({
        id: this.filterId(),
        label: `${this.filterTitle()}: ${labelText}`,
        filterId: this.filterId(),
      })
    }
  }

  isCurrencyField(): boolean {
    return this.currency() !== undefined
  }

  getChipPrefix(): string {
    return this.isCurrencyField() ? this.currency()?.symbol ?? '' : ''
  }

  getRangeDivisor(): number {
    return this.isCurrencyField() ? this.currency()?.rate ?? 1 : 1
  }

  static buildFilter(
    filterService: FilterService,
    state: IRangeFilterState,
    parentId: string,
  ): IJsonLogic {
    const { range, includeLow } = state
    return filterService.build(({ gt, gte, lte, and, variable }) => {
      let lowRangeJson = gt(variable(parentId), range[0])
      if (includeLow) {
        lowRangeJson = gte(variable(parentId), range[0])
      }
      return and(lowRangeJson, lte(variable(parentId), range[1]))
    })
  }
}
