import { Injectable } from '@angular/core'
import { ILegacyImageProcessorOptions } from 'src/app/core/interfaces/image.interface'
import { buildLegacyProcessorSrc } from 'src/app/core/utils/legacy-image.util'
import * as watermark from 'watermarkjs'

@Injectable()
export class ImageService {
  buildLegacyProcessorSrc(options: ILegacyImageProcessorOptions): string {
    return buildLegacyProcessorSrc(options)
  }

  async applyWatermark(src: string, logo: string, options = { padding: 10, fontSize: 18 }): Promise<string> {
    if (logo) {
      const corsOptions = {
        init(img: any) {
          img.crossOrigin = 'anonymous'
        }
      }
      return await watermark([src, logo], corsOptions)
        .dataUrl((image: HTMLCanvasElement, logo: HTMLCanvasElement) => {
          const context = image.getContext('2d')
          const width = image.width
          const height = image.height
          if (context) {
            context.globalAlpha = 0.5
            context.font = `${options.fontSize}px serif`
            context.textAlign = 'right'
            context.drawImage(logo, width - options.padding - logo.width, height - options.padding - options.fontSize - logo.height)
            context.fillText(window.location.origin, width - options.padding, height - options.padding)
          }
          return image
        })
    } else {
      return src
    }
  }
}
