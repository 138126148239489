import { Injectable, inject } from '@angular/core'
import { LogService } from './log.service'
import { Meta, Title } from '@angular/platform-browser'
import { ConfigService } from './config.service'
import { IPageSeoConfigData, ISiteSeoConfigData } from 'src/app/core/interfaces/config.interface'
import { ImageService } from './image.service'
import { IAsset } from '../interfaces/asset.interface'
import { DOCUMENT } from '@angular/common'
import { MediaLibraryService } from './media-library.service'
import { ClientService } from './client.service'
import { I18nService } from './i18n.service'
import { ImageSizes, IMediaData } from '../interfaces/media-library.interface'
import { I18nText } from '../interfaces/i18n.service'
import { LegacyImageProcessorTypes } from '../interfaces/image.interface'
import { GlobalDataService } from './global-data.service'

@Injectable()
export class SeoService {
  private _document = inject(DOCUMENT)
  private _titleService = inject(Title)
  private _meta = inject(Meta)
  private _logger = inject(LogService)
  private _configService = inject(ConfigService)
  private _imageService = inject(ImageService)
  private _mediaLibraryService = inject(MediaLibraryService)
  private _clientService = inject(ClientService)
  private _i18n = inject(I18nService)
  private _globalDataService = inject(GlobalDataService)

  init(siteSeoConfig: ISiteSeoConfigData, pageSeoConfig: IPageSeoConfigData, asset?: IAsset): void {

    const { favicon_img_data, social_img_data } = siteSeoConfig
    const { title, description } = pageSeoConfig
    this._deleteExistingStructuredData()
    if (title) {
      this._setTitle(this.buildPageTitle(title, asset))
      this._setMeta({ property: 'og:title', content: this.buildPageTitle(title, asset) })
    }
    if (description) {
      this._setMeta({ name: 'description', content: this.buildPageDescription(description) })
      this._setMeta({ property: 'og:description', content: this.buildPageDescription(description) })
    }
    if (favicon_img_data) {
      this._setFavicon(favicon_img_data.urls[ImageSizes.Favicon])
    }
    this._setMeta({ property: 'og:url', content: this._document.location.origin + this._document.location.pathname })
    this.buildSocialImage(social_img_data, asset)
    this._setCanonicalTag(this._document.location.origin + this._document.location.pathname)

    if (asset) {
      this._setStructuredData([{
        '@context': 'http://schema.org',
        '@type': 'Product',
        brand: asset.make,
        model: asset.model,
        name: asset.make + ' ' + asset.model,
        category: asset.category,
        description: asset.detailed_description,
        image: this._imageService.buildLegacyProcessorSrc({ guid: asset.images[0], transparent: false, type: LegacyImageProcessorTypes.ItemDetailExtended }),
        offers: {
          '@type': 'Offer',
          price: asset.list_price,
          priceCurrency: asset.currency_type,
          availability: 'InStock',
          url: this._document.location.href,
          seller: {
            '@type': 'Organization',
            name: title,
          },
          itemCondition: 'UsedCondition',
        },
      }, {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': this._document.location.origin,
              name: 'Home',
            },
          },
          {
            '@type': 'ListItem',
            position: 2,
            item: {
              '@id': this._document.location.origin + '/search',
              name: 'Search',
            },
          },
          {
            '@type': 'ListItem',
            position: 3,
            item: {
              '@id': this._document.location.origin + this._document.location.pathname,
              name: asset.make + ' ' + asset.model,
            },
          },
        ],
      }], 'productData')
    }

    if (window.location.pathname === '/' && this._configService.config().page.home.components.heroBanner.enabled) {
      const url = this._getImageSizeUrl(this._configService.config().page.home.components.heroBanner.data.bannerSize ,this._configService.config().page.home.components.heroBanner.data.imageSrcData)
      if (url) {
        this._setPreloadLink(
          url,
          'image', {
            imagesizes: this._mediaLibraryService.getImageSizes(this._configService.config().page.home.components.heroBanner.data.imageSrcData),
            imagesrcset: this._mediaLibraryService.getImageSrcSet(this._configService.config().page.home.components.heroBanner.data.imageSrcData),
          })
      }
    }

    this._globalDataService.loadedLanguages().forEach((language) => {
      const { code } = language
      const currentUrl = this._document.location.href
      const isCurrentLanguage = this._globalDataService.selectedLanguage().code === code
      this._setHrefLangLink(currentUrl.replace(/\/([a-z]{2})-([a-z]{2}(\/|$))/im, `/${code}/`), code)
      if (isCurrentLanguage) {
        this._setHrefLangLink(currentUrl.replace(/\/([a-z]{2})-([a-z]{2}(\/|$))/im, `/${code}/`), 'x-default')
      }
    })
  }

  allowRobots(): void {
    this._setMeta({ name: 'robots', content: 'index, follow' })
    this._setMeta({ name: 'googlebot', content: 'index, follow' })
  }

  blockRobots(): void {
    this._setMeta({ name: 'robots', content: 'noindex, nofollow' })
    this._setMeta({ name: 'googlebot', content: 'noindex, nofollow' })
  }

  private _setTitle(title: string): void {
    this._titleService.setTitle(title)
    this._logger.debug(`Title set to [ ${title} ]`)
  }

  private _setMeta(info: { name?: string, property?: string, content: string }): void {
    this._meta.updateTag(info)
    this._logger.debug(`Meta tag [ ${info.name ?? info.property} ] set to [ ${info.content} ]`)
  }

  private _setFavicon(faviconUrl: string) {
    this._document.getElementById('appFavicon')?.setAttribute('href', faviconUrl)
  }

  private _setCanonicalTag(url: string) {
    const canonicalLink = this._document.querySelector('link[rel="canonical"]')
    if (canonicalLink) {
      canonicalLink.setAttribute('href', url)
    } else {
      const link: HTMLLinkElement = this._document.createElement('link')
      link.setAttribute('rel', 'canonical')
      link.setAttribute('href', url)
      this._document.head.appendChild(link)
      this._logger.debug(`Canonical tag set to [ ${url} ]`)
    }
  }

  private _setPreloadLink(url: string, as: string, otherAttributes: Record<string, string>): void {
    const link: HTMLLinkElement = this._document.createElement('link')
    link.setAttribute('rel', 'preload')
    link.setAttribute('href', url)
    link.setAttribute('as', as)
    for (const key in otherAttributes) {
      if (Object.prototype.hasOwnProperty.call(otherAttributes, key)) {
        link.setAttribute(key, otherAttributes[key])
      }
    }
    this._document.head.appendChild(link)
    this._logger.debug(`Preload link tag set to [ ${url} ]`)
  }

  private _setHrefLangLink(url: string, hrefLang: string): void {
    const existingHrefLangLink = this._document.querySelector(`link[hreflang="${hrefLang}"]`)
    if (existingHrefLangLink) {
      existingHrefLangLink.setAttribute('href', url)
      return
    }
    const link: HTMLLinkElement = this._document.createElement('link')
    link.setAttribute('rel', 'alternate')
    link.setAttribute('href', url)
    link.setAttribute('hreflang', hrefLang)
    this._document.head.appendChild(link)
    this._logger.debug(`Hreflang link tag set to [ ${url} ] for [ ${hrefLang} ]`)
  }

  private _getImageSizeUrl(bannerSize: string, imageSrcData: IMediaData): string {
    switch (bannerSize) {
      case 'aspect-ratio':
        return imageSrcData?.urls?.[ImageSizes.HeroBannerAspectRatio]
      case 'small':
        return imageSrcData?.urls?.[ImageSizes.HeroBannerSmall]
      case 'medium':
        return imageSrcData?.urls?.[ImageSizes.HeroBannerMedium]
      case 'large':
        return imageSrcData?.urls?.[ImageSizes.HeroBannerLarge]
      default:
        return imageSrcData?.urls?.[ImageSizes.Full]
    }
  }

  private _deleteExistingStructuredData(): void {
    const existingStructuredData = this._document.querySelector('script[type="application/ld+json"][data-name="productData"]')
    if (existingStructuredData) {
      existingStructuredData.remove()
    }
  }

  private _setStructuredData(data: Record<string, any>[], name: string): void {
    const script: HTMLScriptElement = this._document.createElement('script')
    script.type = 'application/ld+json'
    script.setAttribute('data-name', name)
    script.text = JSON.stringify(data)
    this._document.head.appendChild(script)
    this._logger.debug(`Structured data set to [ ${JSON.stringify(data)} ]`)
  }

  buildPageDescription(description: I18nText | string, asset?: IAsset): string {
    let i18nDescription = this._i18n.get(description)
    i18nDescription = this.replaceVariables(i18nDescription, asset)
    return i18nDescription
  }

  buildPageTitle(title: I18nText | string, asset?: IAsset): string {
    let pageTitle = this._i18n.get(title)
    pageTitle = this.replaceVariables(pageTitle, asset)
    return `${pageTitle ? `${pageTitle} | ` : ''}${this._clientService.getClientName()}`
  }

  replaceVariables(text: string, asset?: IAsset): string {
    if (!asset) {
      return text
    }
    return text.replace(/<(.+?)>/g, (match: string, propertyName: string) => {
      return asset[propertyName as keyof IAsset] as string
    })
  }

  buildSocialImage(social_img_data: IMediaData | undefined, asset: IAsset | undefined): void {
    if (asset && asset.images.length > 0) {
      this._setMeta({ property: 'og:image', content: this._imageService.buildLegacyProcessorSrc({ guid: asset.images[0], transparent: false, type: LegacyImageProcessorTypes.ItemDetailExtended }) })
      return
    }

    if (!social_img_data) {
      return
    }

    this._setMeta({ property: 'og:image', content: social_img_data.urls[ImageSizes.SocialMediaShare] ?? '' })
  }
}
