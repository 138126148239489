import { CommonModule } from '@angular/common'
import { Component, OnInit, inject, input, model } from '@angular/core'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter } from 'rxjs'
import { IJsonLogic } from 'src/app/core/interfaces/json-logic.interface'
import { FilterId, IFilterItem } from 'src/app/core/interfaces/filter.interface'


export interface ISelectedItem {
  value: string
  children?: ISelectedItem[]
}

export type IDrilldownFilterState = ISelectedItem[]

export function getEmptyDrilldownFilterState(): IDrilldownFilterState {
  return []
}

export const checkDrilldownFilterEmpty = (state: IDrilldownFilterState) => state.length === 0

@Component({
  selector: 'app-drilldown-filter',
  standalone: true,
  templateUrl: './drilldown-filter.component.html',
  styleUrls: ['./drilldown-filter.component.scss'],
  imports: [
    CommonModule,
    MatCheckboxModule,
  ],
})
export class DrilldownFilterComponent implements OnInit {
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  protected filterService = inject(FilterService)

  filterId = input.required<FilterId>()
  state = model.required<IDrilldownFilterState>()
  childTitle = input<string>('')
  settings = input.required<IFilterItem[]>()

  constructor() {
    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(),
      filter(chip => chip.filterId === this.filterId()),
    ).subscribe(chip => {
      if (chip.parentId) {
        this.removeChild(chip.parentId, chip.id)
      } else {
        this.removeParent(chip.id)
      }
    })
    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.clearAll()
    })
  }

  ngOnInit(): void {
    DrilldownFilterComponent.fixMissingParents(this.state(), this.settings())
    this.initChips()
  }

  initChips(): void {
    this.state().forEach(item => {
      this._chipsService.addChip({
        id: item.value,
        label: item.value,
        filterId: this.filterId(),
      })
      if (item.children) {
        item.children.forEach(child => {
          this._chipsService.addChip({
            id: child.value,
            label: child.value,
            filterId: this.filterId(),
            parentId: item.value,
          })
        })
      }
    })
  }

  isParentSelected(value: string): boolean {
    return !!this.state().find(i => i.value === value)
  }

  isChildSelected(parentValue: string, value: string): boolean {
    return !!this.state().find(i => i.value === parentValue)?.children?.find(c => c.value === value)
  }

  handleParentChange(event: MatCheckboxChange, value: string): void {
    if (event.checked) {
      this.addParent(value)
    } else {
      this.removeParent(value)
    }
  }

  addParent(value: string): void {
    this.state.update(items => [...items, { value }])
    this._chipsService.addChip({
      id: value,
      label: value,
      filterId: this.filterId(),
    })
  }

  removeParent(value: string): void {
    const index = this.state().findIndex(i => i.value === value)
    if (index >= 0) {
      this.state()[index].children?.forEach(child => {
        this._chipsService.removeChip(child.value)
      })
      this.state.update(items => items.filter((_, i) => i !== index))
      this._chipsService.removeChip(value)
    }
  }

  handleChildChange(event: MatCheckboxChange, parentValue: string, value: string): void {
    const parent = this.state().find(i => i.value === parentValue)
    if (!parent) {
      return
    }
    if (event.checked) {
      this.addChild(parent, value)
    } else {
      this.removeChild(parent, value)
    }
  }

  addChild(parent: ISelectedItem, value: string): void {
    if (!parent.children) {
      parent.children = []
    }
    parent.children.push({
      value,
    })
    this.state.update(items => {
      const index = items.findIndex(i => i.value === parent.value)
      if (index >= 0) {
        items[index] = parent
      }
      return [
        ...items,
      ]
    })
    this._chipsService.addChip({
      id: value,
      label: value,
      filterId: this.filterId(),
      parentId: parent.value,
    })
  }

  removeChild(parent: ISelectedItem | string, value: string): void {
    if (typeof parent === 'string') {
      parent = this.state().find(i => i.value === parent) as ISelectedItem
      if (!parent) {
        return
      }
    }
    const index = (parent.children || []).findIndex(i => i.value === value)
    if (index >= 0) {
      (parent.children || []).splice(index, 1)
      this._chipsService.removeChip(value)
    }
    if (parent.children?.length === 0) {
      delete parent.children
    }
    this.state.update(items => {
      const parentIndex = items.findIndex(i => i.value === parent.value)
      if (parentIndex >= 0) {
        items[parentIndex] = parent
      }
      return [
        ...items,
      ]
    })
  }

  clearAll(): void {
    this.state.set(getEmptyDrilldownFilterState())
  }

  static buildFilters(
    filterService: FilterService,
    state: IDrilldownFilterState,
    settingsId: string,
    parentId: string,
    childId?: string,
  ): IJsonLogic {
    DrilldownFilterComponent.fixMissingParents(state, filterService.filterSettings[settingsId])
    const filters: IJsonLogic = filterService.build(({ and, or, inArray, equals, variable }) => {
      const allFilters: IJsonLogic[] = []
      state.forEach(parentItem => {
        const filtersForGroup = []
        const parentFilter = equals(
          variable(parentId),
          parentItem.value,
        )
        filtersForGroup.push(parentFilter)
        if (parentItem.children && childId) {
          const childFilter = inArray(
            variable(childId),
            parentItem.children.map(child => child.value),
          )
          filtersForGroup.push(childFilter)
        }
        if (filtersForGroup.length > 1) {
          allFilters.push(
            and(...filtersForGroup)
          )
        } else {
          allFilters.push(filtersForGroup[0])
        }
      })
      return or(...allFilters)
    })
    return filters
  }

  // if a filter has a child but not a parent, find and add the parent
  // (happens when loading a redirect link where the parent isn't included, e.g. /equipment/search/subcategory/0-114 HP Crawler Dozers)
  static fixMissingParents(state: IDrilldownFilterState, settings: IFilterItem[]): IFilterItem[] {
    state.forEach(item => {
      if (!item.value) {
        const child = item.children?.[0]
        const parent = settings.find(s => s.children?.find(c => c.value === child?.value))
        if (parent) {
          item.value = parent.value
        }
      }
    })
    return settings
  }
}
