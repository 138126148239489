import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { IConfirmDialogOptions } from 'src/app/core/interfaces/dialog.interface'


@Component({
  selector: 'confirm-dialog-component',
  standalone: true,
  template: `
    @if (data.title) {
      <div data-testid="title" mat-dialog-title>{{data.title}}</div>
    }
    @if (data.content) {
      <div data-testid="content" mat-dialog-content>
        {{data.content}}
      </div>
    }
    <div mat-dialog-actions>
      <button data-testid="cancel" mat-button mat-dialog-close [mat-dialog-close]="'cancel'">{{data.cancelLabel}}</button>
      <button data-testid="confirm" mat-button mat-dialog-close [mat-dialog-close]="'confirm'">{{data.confirmLabel}}</button>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      padding: 0 15px;
    }
  `],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class ConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogOptions) {}
}
