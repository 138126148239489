import { Component, input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'

export enum LabelType {
  filled = 'filled',
  outlined = 'outlined',
  reversed = 'reversed',
}

export enum LabelModifier {
  primary = '--primary',
  secondary = '--secondary',
  error = '--error',
  warning = '--warning',
  info = '--info'
}

@Component({
  selector: 'app-label',
  template: `
    <div class="Label Label-{{type()}} Label-{{type()}}{{modifier() ?? ''}}">
      @if (leftIcon(); as leftIcon) {
        <mat-icon
          data-testid="left-icon"
          [class.material-icons-outlined]="outlinedIcon()"
        >{{leftIcon}}</mat-icon>
      }
      @if (text(); as text) {
        <span
          data-testid="label-text"
          class="Label__text"
        >{{text}}</span>
      }
      @if (rightIcon(); as rightIcon) {
        <mat-icon
          data-testid="right-icon"
          [class.material-icons-outlined]="outlinedIcon()"
        >{{rightIcon}}</mat-icon>
      }
    </div>
  `,
  styleUrls: ['./label.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule]
})
export class LabelComponent {
  type = input<LabelType>(LabelType.filled)
  modifier = input<LabelModifier>()
  leftIcon = input<string>()
  rightIcon = input<string>()
  outlinedIcon = input<boolean>(false)
  text = input<string>()
}
