@for (setting of settings(); track setting) {
  <div>
    <mat-checkbox
      type="checkbox"
      (change)="handleParentChange($event, setting.value)"
      [checked]="isParentSelected(setting.value)"
      data-testid="parent-checkbox"
    >{{setting.value}}</mat-checkbox>
    @if (isParentSelected(setting.value) && setting.children) {
      <div class="children-container">
        <div class="child">
          <div class="child-title" data-testid="child-title">{{childTitle()}}</div>
          @for (childSetting of setting.children; track childSetting) {
            <mat-checkbox
              type="checkbox"
              (change)="handleChildChange($event, setting.value, childSetting.value)"
              [checked]="isChildSelected(setting.value, childSetting.value)"
              [disabled]="!isParentSelected(setting.value)"
              data-testid="child-checkbox"
            >{{childSetting.value}}</mat-checkbox>
          }
        </div>
      </div>
    }
  </div>
}
